<template>
  <div class="card">
    <div class="card-body padding-all-sm">
      <!-- card image -->
      <img :src="icon" />

      <div class="body-small">XXXX-XXXX-XXXX-{{ card.last_four }}</div>

      <!-- delete -->
      <div class="icon-trash-off cursor-pointer" @click="toggleOptions"></div>
    </div>

    <!-- card options -->
    <div class="card-options shadow-md" v-if="showOptions">
      <div
        class="
          padding-all-xs
          heading-title
          cursor-pointer
          border-b-1 border-black-10
        "
      >
        {{ $translations['payment-list']['confirm-delete'].title }}
      </div>
      <div
        class="
          padding-y-xs
          heading-title
          cursor-pointer
          border-b-1 border-black-5
        "
        @click="toggleOptions"
      >
        {{ $translations['payment-list']['confirm-delete'].no }}
      </div>
      <div
        class="padding-y-xs heading-title text-red cursor-pointer"
        @click="deletePaymentMethod(card.id)"
      >
        {{ $translations['payment-list']['confirm-delete'].yes }}
      </div>
    </div>
  </div>
</template>

<script>
import { PaymentMethods } from '@seliaco/red-panda'

export default {
  name: 'PaymentMethodCard',
  props: {
    card: Object
  },
  data () {
    return {
      showOptions: false
    }
  },
  methods: {
    deletePaymentMethod (id) {
      this.loading = true

      PaymentMethods.remove(id)
        .then(() => {
          this.$emit('deleted', id)
          this.toggleOptions()
          this.$toast({
            text: this.$translations.success.default,
            severity: 'success'
          })
        })
        .catch(() => {
          this.$toast({
            text: this.$translations.error['delete-payment-method'],
            severity: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleOptions () {
      this.showOptions = !this.showOptions
    },
    inArray (id) {
      const result = this.cardOptions.filter((item) => item.id === id)

      return result.length
    }
  },
  computed: {
    icon () {
      return require(`@/assets/icons/payments/cards/${this.card.brand.toLowerCase()}.png`)
    }
  }
}
</script>

<style lang="sass" scoped>
.card
  width: 250px
  position: relative
  &-body
    border: 1px solid var(--purple)
    background: var(--gray-5)
    border-radius: 16px
    display: flex
    align-items: center
    justify-content: space-between
    img
      width: 48px
      height: 48px

.card-options
  background: #ffffff
  border: 1px solid var(--black-10)
  border-radius: 24px
  text-align: center
  position: absolute
  top: 35px
  left: 100px
  z-index: 300
  width: 246px
</style>
