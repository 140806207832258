<template>
  <div class="flex justify-between pb-2">
    <Card
      :name="$translations.payment['payment-methods'].title.CARD"
      :image="require('@/assets/icons/payments/credit-card.svg')"
      :selected="paymentMethodType === creditCard"
      @click.native="$emit('payment-method-selected', creditCard)" />
<!--    <Card-->
<!--      v-bind:name="$translations.payment['payment-methods'].title.NEQUI"-->
<!--      v-bind:image="require('@/assets/icons/payments/nequi.svg')"-->
<!--      v-bind:selected="paymentMethodType === nequi"-->
<!--      @click.native="$emit('payment-method-selected', nequi)" />-->
  </div>
</template>
<script>
import Card from '@/views/payments/components/Card'

export default {
  components: {
    Card
  },
  props: {
    paymentMethodType: String
  },
  data () {
    return {
      creditCard: 'CARD',
      pse: 'PSE',
      nequi: 'NEQUI'
    }
  }
}
</script>
