<template>
  <div class="flex flex-wrap border-1 rounded-xl w-15 h-15 justify-center padding-top cursor-pointer"
    v-bind:class="{
      'border-purple-60 bg-purple-10 text-purple': selected,
      'border-grey-20 bg-gray-5 text-black-100': !selected
    }">
    <div class="flex rounded-full h-7 w-7 items-center justify-center bg-white shadow-md">
      <img class="self-center flex-none duration-200 transition object-center object-cover"
        :src="image" />
    </div>
    <div class="flex content-title w-full justify-center">{{ name }}</div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    name: String,
    selected: Boolean
  }
}
</script>
