<template>
  <div>
    <!-- backdrop -->
    <div v-on:click="$emit('on-nav-back')" class="fixed bg-black h-screen w-screen top-0 left-0 right-0 bottom-0 z-50 opacity-60"></div>

    <!-- modal -->
    <div class="modal-container" :class="{ 'modal-container-with-action': action }">
      <!-- header -->
      <div class="modal-header">
        <!-- arrow back -->
        <div class="modal-header-action" v-on:click="$emit('on-nav-back')">
          <div class="icon-arrow-big-outline-left heading-big"></div>
        </div>

        <!-- title -->
        <div class="modal-header-title heading-small">
          {{ title }}
        </div>

        <!-- spacer -->
        <div></div>
      </div>

      <!-- content -->
      <div class="modal-content padding-all" :class="{ 'padding-all': !noPadding}">
        <slot name="content"></slot>
      </div>

      <!-- content bottom -->
      <div class="modal-action padding-all" v-if="action">
        <Button @clicked="$emit('action-clicked')" :text="action.text" />
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'

export default {
  name: 'ModalContainer',
  components: {
    Button
  },
  props: {
    title: String,
    transparentBackground: {
      type: Boolean,
      default: false
    },
    noPadding: Boolean,
    action: {
      text: String
    }
  }
}
</script>

<style lang="sass">
.modal-container
  border-radius: 24px 24px 0 0
  height: calc(100vh - 128px - env(safe-area-inset-bottom) - env(safe-area-inset-top))
  max-width: 600px
  padding-bottom: calc(env(safe-area-inset-bottom))
  z-index: 99
  background-color: #ffffff
  position: fixed
  margin: calc(env(safe-area-inset-top) + 128px) auto 0
  left: 0
  right: 0
  bottom: 0
  display: grid
  grid-template-rows: 60px calc(100% - 60px - calc(env(safe-area-inset-bottom)))

.modal-container-with-action
  grid-template-rows: 60px calc(100% - 80px - 60px - calc(env(safe-area-inset-bottom))) 80px

.modal-header
  border-bottom: 1px solid var(--gray-10)
  align-self: center
  height: 100%
  padding: 0 20px
  display: grid
  grid-template-columns: 20px 1fr 20px

  &-action
    font-size: 28px
    cursor: pointer
    align-self: center

  &-title
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    text-align: center
    align-self: center

.modal-content
  overflow-y: auto
  height: 100%

.modal-action
  border-top: 1px solid var(--gray-10)
  align-self: center
  height: 100%

</style>
