<template>
  <div>
    <PageContainer
      :title="$translations['payment-list'].title"
      :back="actions.back"
    >
      <template v-slot:content>
        <div class="page-segment">
          <div class="page-segment-content">
            <!-- loader -->
            <template v-if="loading">
              <SkeletonDefault />
            </template>

            <template v-else>
              <!-- Funds -->
              <div class="funds margin-top">
                <div class="funds-header margin-bottom">
                  <!-- flex spacer -->
                  <div></div>
                  <div
                   class="small-tag text-gray-50"
                   v-text="$translations.payment.balance"
                  />

                  <span
                    v-tooltip="$translations.payment['funds-tooltip']"
                    class="icon-question-mark-off tooltip-icon text-gray-40"/>
                </div>

                <div class="heading-big funds-balance margin-bottom-xl padding-bottom-xl" v-text="userBalance" />
              </div>

              <!-- cards header -->
              <div class="card-header">
                <div
                  class="heading-small"
                  v-text="$translations.payment['payment-method']"
                />
                <div
                  class="flex text-purple body cursor-pointer"
                  @click="openAdd()"
                >
                  <div class="icon-plus self-center"></div>
                  <div class="self-center" v-text="$translations.payment.add" />
                </div>
              </div>

              <!-- cards -->
              <div
                class="margin-y"
                v-for="(card, key) in paymentMethods"
                :key="key"
              >
                <PaymentMethodCard
                  :card="card"
                  @deleted="onPaymentMethodDeleted"
                />
              </div>

              <!-- cards datazero -->
              <div
                class="card-datazero margin-y padding-all"
                v-if="paymentMethods.length === 0"
              >
                <img
                  width="56px"
                  src="@/assets/icons/payments/no-payment-methods.png"
                  alt="no-payment-methods"
                />
                <div
                  class="text-purple-50 heading-tag"
                  v-text="$translations.payment.payments['no-payment-methods']"
                />
              </div>

              <!-- payment history header -->
              <div class="payment-history-header padding-y">
                <div class="heading-small">
                  {{ $translations.payment['payment-history'] }}
                </div>
                <div class="payment-history-header-action">
                  <div
                    class="
                      payment-history-header-action-text
                      content-title
                      cursor-pointer
                    "
                    @click="openToggle = !openToggle"
                  >
                    {{ $translations['order-by'].title }}
                    <i class="icon-arrow-big-outline-down" />
                  </div>
                  <div
                    class="payment-history-header-action-modal"
                    v-show="openToggle"
                  >
                    <ul>
                      <li
                        class="padding-x padding-y-xs body cursor-pointer"
                        v-for="(item, index) in orderByItems"
                        :key="index"
                        @click="changeSort(item.sortMethod, item.ascending)"
                      >
                        {{ item.label }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- payment history -->
              <PaymentHistoryCard
                v-for="item in sortedArray"
                :key="item.id"
                :item="item"
              />

              <!-- payment history data zero -->
              <div
                v-if="paymentHistory.length === 0"
                class="payment-history-data-zero padding-y margin-bottom-xl"
              >
                <img
                  width="176px"
                  src="@/assets/icons/payments/no-payments.png"
                  alt="no-payments"
                />
                <div class="text-purple-50 heading">
                  {{ $translations.payment.payments['no-payments'] }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </PageContainer>

    <!-- create payment method modal -->
    <PaymentMethodsModal
      v-show="openPaymentMethods"
      v-on:closeModal="closeModal"
      v-on:createPaymentMethod="createPaymentMethod"
      :loading="loading"
      :card="card"
      :paymentMethodAction="paymentMethodAction"
      :paymentMethodType="paymentMethodType"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { PaymentMethods, Transactions, PageContainer, tooltip, Wallet } from '@seliaco/red-panda'
import SkeletonDefault from '@/components/skeletons/SkeletonDefault'
import PaymentHistoryCard from './components/PaymentHistoryCard'
import PaymentMethodCard from './components/PaymentMethodCard'
import PaymentMethodsModal from './modal/PaymentMethodsModal'

export default {
  name: 'PaymentList',
  components: {
    PaymentMethodCard,
    SkeletonDefault,
    PageContainer,
    PaymentHistoryCard,
    PaymentMethodsModal
  },
  directives: { tooltip },
  data () {
    return {
      card: {
        name: '',
        number: '',
        expireDate: null,
        cvv: ''
      },
      paymentMethodType: 'CARD',
      cardOptions: [],
      loading: false,
      open: true,
      openToggle: false,
      openPaymentMethods: false,
      sortMethod: 'paymentTime',
      ascending: false,
      paymentMethod: {},
      paymentMethodAction: '',
      paymentMethods: [],
      paymentHistory: [],
      orderByItems: [
        { label: this.$translations['order-by']['more-recent'], sortMethod: 'paymentTime', ascending: false },
        { label: this.$translations['order-by']['less-recent'], sortMethod: 'paymentTime', ascending: true }
      ],
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        }
      },
      userBalance: 0
    }
  },
  mounted () {
    this.getUsrBalance()
    this.getPaymentMethods()
    this.getPaymentHistory()
  },
  methods: {
    getUsrBalance () {
      this.loading = true
      Wallet.get()
        .then((response) => {
          this.userBalance = `${response.balance} ${response.currency.toUpperCase()}`
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: 'No fue posible obtener el balance'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPaymentMethods () {
      this.loading = true

      PaymentMethods.list()
        .then((response) => {
          this.paymentMethods = response
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: 'No fue posible obtener los métodos de pago'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPaymentHistory () {
      this.loading = true

      Transactions.list()
        .then((response) => {
          this.paymentHistory = response
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: 'No fue posible obtener las transacciones'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeModal () {
      this.openPaymentMethods = false
      this.card = { name: '', number: '', expireDate: null, cvv: '' } // empty card data
    },
    changeSort (sortMethod, ascending) {
      this.openToggle = !this.openToggle
      this.sortMethod = sortMethod
      this.ascending = ascending
    },
    openAdd () {
      this.cardOptions = []
      this.paymentMethod = {}
      this.paymentMethodAction = 'add'
      this.openPaymentMethods = true
    },
    createPaymentMethod () {
      this.loading = true

      PaymentMethods.createCard({
        number: this.card.number,
        exp_month: `${this.card.expireDate.slice(0, 2)}`,
        exp_year: `${this.card.expireDate.slice(2, 4)}`,
        cvc: this.card.cvv,
        card_holder: this.card.name
      })
        .then(() => {
          this.getPaymentMethods()

          this.card = { name: '', number: '', expireDate: '', cvv: '' } // empty card data
          this.openPaymentMethods = false
        })
        .catch((error) => {
          this.$toast({
            text: error.message || this.$translations.error['form-invalid'],
            severity: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onPaymentMethodDeleted (id) {
      this.paymentMethods = this.paymentMethods.filter((item) => {
        if (item.id !== id) {
          return item
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    sortedArray () {
      let sorted = this.paymentHistory
      if (this.sortMethod === 'paymentTime') {
        sorted = sorted.sort((a, b) => {
          return a.created_at - b.created_at
        })
      }
      if (!this.ascending) {
        return sorted.reverse()
      } else {
        return sorted.reverse()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.card-header,
.payment-history-header
  display: flex
  justify-content: space-between
  align-items: center

.card-datazero
  display: flex
  align-items: center
  border: 0.5px solid var(--purple-20)
  border-radius: 16px
  gap: 8px

.payment-history-header
  &-action
    position: relative
    &-text
      display: flex
      align-items: center
      gap: 8px
    &-modal
      position: absolute
      width: max-content
      border: 1px solid var(--gray-50)
      border-radius: 8px
      background: var(--white)

.payment-history-data-zero
  display: flex
  flex-direction: column
  align-items: center

.funds
  &-header,
  &-balance
    display: flex
    width: 100%
    justify-content: space-between
  &-balance
    justify-content: center
</style>
