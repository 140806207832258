<template>
  <div>
    <InputTextField
      class="margin-top"
      v-model="card.name"
      :label="$translations.payment['card-information'].name"
    />
    <InputMaskField
      class="margin-top"
      v-model="card.number"
      mask="#### #### #### ####"
      :label="$translations.payment['card-information'].number"
    />

    <div class="margin-bottom bottom-fields">
      <!-- expiration date -->
      <div class="bottom-fields-field margin-top">
        <div class="text-gray-50 body-small" v-text="$translations['add-credit-card'].exp" />
        <InputMaskField
          v-model="card.expireDate"
          mask="## / ##"
          label="MM / YY"
          :format-function="formatExpiryDate"
        />
      </div>

      <!-- csv-->
      <div class="bottom-fields-field margin-top">
        <div class="text-gray-50 body-small" v-text="$translations['add-credit-card'].cvc" />
        <InputMaskField
          v-model="card.cvv"
          mask="####"
          label="000"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  InputMaskField,
  InputTextField
} from '@seliaco/sea-otter'
import ValidateCardDateMixin from "@/mixins/validate-card-date-mixin";

export default {
  mixins: [ValidateCardDateMixin],
  components: {
    InputMaskField,
    InputTextField
  },
  props: {
    card: Object
  }
}
</script>
<style lang="sass" scoped>
.input-with-icon
  input
    padding-left: 56px !important
.bottom-fields
  display: flex
  gap: 16px
  &-field
    display: flex
    flex-direction: column
    justify-content: flex-start
    gap: 4px
</style>
