<template>
  <div class="payment-history-card">
    <!-- price -->
    <div class="payment-history-card-price">
      {{ price | CurrencyFilter(item.currency.toUpperCase()) }}
    </div>

    <!-- toggle -->
    <div
      class="payment-history-card-toggle"
      :class="toggleIcon"
      @click="expandContent(expanded)"
      v-if="item.appointment"
    />

    <!-- date & payment method -->
    <div class="payment-history-card-date" v-text="dateAndPaymentMethod" />

    <!-- payment info -->
    <div class="payment-history-card-info" v-text="paymentInfo" />

    <!-- appointment info -->
    <div
      class="payment-history-card-detail"
      v-if="expanded"
      @click="handleAppointmentDetail"
      v-text="$translations.cards['see-appointment']"
    />
  </div>
</template>

<script>
import { CurrencyFilter } from '@seliaco/red-panda'

export default {
  name: 'PaymentHistoryCard',
  props: {
    item: Object
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    expandContent () {
      this.expanded = !this.expanded
    },
    handleAppointmentDetail () {
      this.$router.push({
        name: 'AppointmentDetails',
        params: { id: this.item.appointment.id }
      })
    }
  },
  filters: {
    CurrencyFilter
  },
  computed: {
    price () {
      return this.item.invoice?.total_in_cents / 100
    },
    toggleIcon () {
      return this.expanded
        ? 'icon-arrow-big-outline-up'
        : 'icon-arrow-big-outline-down'
    },
    paymentInfo () {
      return this.item.appointment
        ? this.item.appointment.service.name
        : this.$translations.payment['package-payment']
    },
    dateAndPaymentMethod () {
      const result = [this.$moment(this.item.created_at).format('DD/MM/YYYY')]

      if (this.item.payment_method?.brand) {
        result.push(this.item.payment_method.brand)
      }

      if (this.item.payment_method?.last_four) {
        result.push(this.item.payment_method.last_four)
      }

      return result.join(' - ')
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-history-card {
  display: grid;
  grid-template-columns: 1fr 24px;
  background: var(--gray-5);
  border-radius: 16px;
  border: 1px solid var(--purple-20);
  padding: 16px;
  margin-bottom: 16px;

  &-price {
    font-weight: bold;
    font-size: 16px;
    align-self: center;
  }

  &-toggle {
    font-size: 24px;
    cursor: pointer;
  }

  &-date {
    margin: 6px 0;
  }

  &-price,
  &-date,
  &-info,
  &-detail {
    grid-column: 1;
    font-size: 12px;
    line-height: 1.2;
  }

  &-detail {
    margin-top: 6px;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
