export default {
  methods: {
    formatExpiryDate (inputValue) {
      // Init the formatted value
      let formattedValue = inputValue;

      // Validating the first digit
      if (inputValue.length === 1) {
        const value = parseInt(inputValue, 10);
        if (value > 1) {
          formattedValue = '0';
        }
      }

      // Validating the month (first two digits)
      if (inputValue.length > 1) {
        let month = parseInt(inputValue.substring(0, 2), 10);
        if (isNaN(month) || month < 1 || month > 12) {
          formattedValue = '01';
        } else {
          formattedValue = month.toString().padStart(2, '0');
        }
      }

      // Validating the third digit (first digit of the year)
      if (inputValue.length > 2) {
        const value = parseInt(inputValue.substring(2, 3), 10);
        if (value < 2) {
          formattedValue += '2';
        } else {
          formattedValue += value.toString();
        }
      }

      // Validating the fourth digit (second digit of the year)
      if (inputValue.length > 3) {
        const currentYear = new Date().getFullYear().toString().slice(2);
        const maxYear = (parseInt(currentYear, 10) + 100).toString();

        let year = inputValue.substring(2, 4);
        let yearInt = parseInt(year, 10);

        if (isNaN(yearInt) || yearInt < parseInt(currentYear, 10) || yearInt > parseInt(maxYear, 10)) {
          formattedValue = formattedValue.slice(0, 2) + currentYear;
        } else {
          formattedValue += year.slice(1, 2);
        }
      }

      return formattedValue;
    }
  }
}
