<template>
  <ModalContainer
    :title="$translations['payment-methods-modal'].title[isAction]"
    v-on:on-nav-back="closeModal"
    v-if="isAction"
  >
    <template v-slot:content>
      <CardList
        :paymentMethodType="paymentMethodType"
        v-on:payment-method-selected="paymentMethodSelected($event)"
      />

      <PaymentMethodInput :card="card" />

      <Button
        @clicked="$emit('createPaymentMethod')"
        color="purple"
        :disable="!canContinue"
        :loading="loading"
        :text="$translations.payment['add-payment-method']"
      />
    </template>
  </ModalContainer>
</template>
<script>
import ModalContainer from '@/components/modals/ModalContainer'
import CardList from '../components/CardList'
import PaymentMethodInput from '../components/PaymentMethodInput'
import { Button } from '@seliaco/red-panda'

export default {
  components: {
    ModalContainer,
    CardList,
    PaymentMethodInput,
    Button
  },
  props: {
    loading: Boolean,
    card: Object,
    paymentMethodAction: String,
    paymentMethodType: String
  },
  computed: {
    isAction () {
      return this.paymentMethodAction
    },
    canContinue () {
      return Boolean(
        this.paymentMethodType &&
          this.card.number !== '' &&
          this.card.number.length === 16 &&
          this.card.expireDate !== null &&
          this.card.cvv !== ''
      )
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    paymentMethodSelected (value) {
      this.paymentMethodType = value
    }
  }
}
</script>
